if (document.querySelectorAll('.song-btn').length > 0) {
    startPlayer();
} else {
    console.log("NONE");
}


function startPlayer() {
    let $songButtons = document.querySelectorAll('.song-btn');

    let $playerContainer = document.querySelector('.player');
    let $playerContainerTitle = $playerContainer.querySelector('.player__nav-text');
    let $playerContainerAudio = $playerContainer.querySelector('#player-audio');
    let $playerCurrentTime = $playerContainer.querySelector('.player__current-time');
    let $playerAllTime = $playerContainer.querySelector('.player__all-time');
    let $playerProgress = $playerContainer.querySelector('.player__progress');
    let $playerProgressContainer = $playerContainer.querySelector('.player__progress-container');
    let $playerButton = $playerContainer.querySelector('.player__btn');
    let $playerContainerCloseBtn = document.querySelector('.player__close');

    let $pauseIcons = document.querySelectorAll('.icon-pause');
    let $playIcons = document.querySelectorAll('.icon-play');

    let currentSong = 0;
    let currentTime = 0;
    let songs = [];


    let playerActiveClass = 'player--active';
    let playerPlayClass = 'player--play';
    let iconHideClass = 'icon--hide';

    $playerContainerCloseBtn.addEventListener('click', () => {
        if ($playerContainer.classList.contains(playerActiveClass)) {
            $playerContainer.classList.remove(playerActiveClass);
        }
        let $btn = Array.from($songButtons).find(item => item.dataset.id == $playerButton.dataset.id);
        initPlay($btn, true);
    });

    $songButtons.forEach(($songBtn, index) => {
        songs.push($songBtn.dataset.id);

        $songBtn.addEventListener('click', e => {
            let $target = e.currentTarget;

            $playerButton.dataset.id = $target.dataset.id;

            initPlay($target);
        });
    });

    $playerButton.addEventListener('click', e => {
        let $btn = Array.from($songButtons).find(item => item.dataset.id == $playerButton.dataset.id);
        initPlay($btn);
    });

    $playerContainerAudio.addEventListener('timeupdate', e => {
        updateProgress(e);
    });

    $playerContainerAudio.addEventListener('ended', e => {
        let index = songs.indexOf(currentSong);

        if (index < songs.length - 1) {
            initPlay($songButtons[index + 1]);
        } else {
            initPlay($songButtons[0]);
        }
    });

    $playerProgressContainer.addEventListener('click', e => {
        setProgress(e);
    });

    function initPlay($btn, manualStop = false) {
        let $pauseIcon = $btn.querySelector('.icon-pause');
        let $playIcon = $btn.querySelector('.icon-play');

        if(manualStop == true)
        {
            checkIcons();
            $pauseIcon.classList.add(iconHideClass);
            $playIcon.classList.remove(iconHideClass);
            pauseSong();
            return
        }

        if (!$playIcon.classList.contains(iconHideClass)) {
            checkIcons();
            $pauseIcon.classList.remove(iconHideClass);
            $playIcon.classList.add(iconHideClass);

            if (currentSong != $btn.dataset.id) {
                currentSong = $btn.dataset.id;
                let data = {
                    'title': $btn.dataset.title,
                    'audio': '/public/storage/songs/' + $btn.dataset.id + '/' + $btn.dataset.audio
                };
                loadSong(data);
            }
            playSong();
        } else {
            checkIcons();
            $pauseIcon.classList.add(iconHideClass);
            $playIcon.classList.remove(iconHideClass);
            pauseSong();
        }
    }

    function checkIcons() {
        $pauseIcons.forEach(item => {
            item.classList.add(iconHideClass);
        });
        $playIcons.forEach(item => {
            item.classList.remove(iconHideClass);
        });
    }

    function loadSong(data) {
        $playerContainerTitle.innerText = data.title;
        $playerContainerAudio.src = data.audio;
        $playerContainerAudio.load();
    }

    function playSong() {
        $playerContainerAudio.play();
        $playerContainer.classList.add(playerPlayClass);
        $playerContainer.classList.add(playerActiveClass);
    }

    function pauseSong() {
        $playerContainerAudio.pause();
        $playerContainer.classList.remove(playerPlayClass);
    }

    function updateProgress(e) {

        let {currentTime, duration} = e.currentTarget;
        let minutes = parseInt(currentTime / 60);
        let seconds = parseInt(currentTime % 60);
        if (seconds < 10) {
            seconds = '0' + seconds;
        }

        let durationMinutes = parseInt(duration / 60);
        let durationSeconds = parseInt(duration % 60);
        if (durationSeconds < 10) {
            durationSeconds = '0' + durationSeconds;
        }

        $playerCurrentTime.innerText = `${minutes}:${seconds}`;
        $playerAllTime.innerText = `${durationMinutes}:${durationSeconds}`;
        let progressPercent = (currentTime / duration) * 100;
        $playerProgress.style.width = `${progressPercent}%`;
    }

    function setProgress(e) {
        let width = e.currentTarget.clientWidth;
        let clickX = e.offsetX;
        let duration = $playerContainerAudio.duration;
        $playerContainerAudio.currentTime = (clickX / width) * duration;
    }
}



