let $navBtn = document.querySelector('.nav__menu');
let $navSidePanel = document.querySelector('.nav');

let $outerNavContainer = document.querySelector('.nav__container')
let $innerNavContainer = document.querySelector('.nav__container-wrapper')

let navSideOpenClass = 'nav--open'


console.log("AAA")
if($navBtn){
    console.log("BBB")
    let navHeight = $innerNavContainer.getBoundingClientRect().height;
    $navBtn.addEventListener('click', () => {
        checkNavOpen($navSidePanel, navHeight);
    })
}

function checkNavOpen($navSidePanel, navHeight){
    if(!$navSidePanel.classList.contains(navSideOpenClass)){
        $outerNavContainer.style.height = navHeight + 'px';
        setTimeout(()=>{
            $navSidePanel.classList.add(navSideOpenClass)
        }, 100)

    }else {
        $outerNavContainer.style.height = 0;
        $navSidePanel.classList.remove(navSideOpenClass)
    }
}
