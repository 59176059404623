window.addEventListener('DOMContentLoaded', function() {
    //modals.open('video-show');

    let $videoModalContent = document.querySelector('#video-show .modal__content');
    let $videoItems = document.querySelectorAll('.video-link');

    let modalBgClass = 'modal__background';

    $videoItems.forEach($videoItem=>{
        $videoItem.addEventListener('click',e=>{
            let $currentTemplate = e.currentTarget.querySelector('.video-template')
            let templateClone = $currentTemplate.content.cloneNode(true);

             $videoModalContent.innerHTML = ''
             $videoModalContent.appendChild(templateClone);
             modals.open('video-show');
        })
    })

    window.addEventListener("click", e=> {
        if (e.target.classList.contains(modalBgClass))
        {
            $videoModalContent.innerHTML = ''
        }
    });

});

