let $navbarWrappers = document.querySelectorAll('.header__nav-wrapper');
let $navbarToggle = document.getElementsByClassName('header__toggle-button')[0];
let $navbar = document.getElementsByClassName('header')[0];

let wrapperOpenClass = 'header__nav-wrapper--open'
let navbarOpenClass = 'header--open'

$navbarWrappers.forEach($wrapper=>{
    console.log($wrapper)
    let $wrapperBtn = $wrapper.querySelector('.header__nav-wrapper-wrap')
    console.log($wrapperBtn)
    $wrapperBtn.addEventListener('click',e=>{
        console.log($wrapper)
        checkWrapperOpen($wrapper);
    })

})
function checkWrapperOpen($wrapper){
    if(!$wrapper.classList.contains(wrapperOpenClass)){
        $wrapper.classList.add(wrapperOpenClass)
    }else {
        $wrapper.classList.remove(wrapperOpenClass)
    }
}


if($navbar){
    if($navbarToggle) {
        $navbarToggle.addEventListener('click', () => {
            checkNavbarToggleClick();
        })
    }
    function checkNavbarToggleClick(){
        if(!$navbar.classList.contains(navbarOpenClass)){
            $navbar.classList.add(navbarOpenClass)
        }else {
            $navbar.classList.remove(navbarOpenClass)
        }
    }
}


